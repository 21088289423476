import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';

function ListadoSurtidos() {
  const { user } = useContext(AuthContext);
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-90);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [totalImporteTabla, setTotalImporteTabla] = useState(0);
  const [totalPiezasTabla, setTotalPiezasTabla] = useState(0);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [photoEvidencia, setPhotoEvidencia] = useState([]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useMemo(()=>{
    axios
    .get(`${URL_SURTIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        // Array para pagination
        let arrayTabla = allSurtidos
          .sort((a, b) => (a.idSurtido > b.idSurtido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              is_active: a.is_active,
              fecha: a.fecha,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              idUser: a.user[0]._id,
              pedidos: a.pedidos[0].idPedido,
              idPedido: a.pedidos[0]._id,
              clientes: a.clientes[0].nombre_comercial,
              codigoClientes: a.clientes[0].codigo,
              idClientes: a.clientes[0]._id,
              observaciones: a.observaciones,
              numero: a.salidaBodega,
              idSurtido: a.idSurtido,
              comisionTDC: a.pedidos[0].comisionTDC,
              comisionFlete: a.pedidos[0].comisionFlete,
              impuestos: a.pedidos[0].impuestos,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              total_cantidad: a.total_cantidad,
              calle: a.clientes[0].calle,
              numero_ext: a.clientes[0].numero_ext,
              numero_int: a.clientes[0].numero_int,
              pais: a.clientes[0].pais,
              estado: a.clientes[0].estado,
              ciudad: a.clientes[0].ciudad,
              delegacion: a.clientes[0].delegacion,
              colonia: a.clientes[0].colonia,
              cp: a.clientes[0].cp,
              paqueteria: a.pedidos[0].paqueteria,
              RFC: a.clientes[0].RFC,
              area: a.areas[0].name,
              vendedor:
                a.colaboradores[0].nombre +
                " " +
                a.colaboradores[0].apellido,
              idVendedor: a.colaboradores[0]._id,
              telefonoVendedor: a.colaboradores[0].telefono,
              telefono: a.clientes[0].telefono,
              idDepartamento: a.clientes[0].departamentosClientes[0]._id,
              departamentoName: a.clientes[0].departamentosClientes[0].name,
              fotoEvidencia: a.fotoEvidencia,
              email: a.clientes[0].email,
              plazo: a.clientes[0].plazo,
              total_cantidad: a.total_cantidad,
              noGuia: a.noGuia,
              noFactura: a.noFactura,
              salidaBodega: a.salidaBodega,
              descuento: a.descuento,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio ,selectedFechaFin])


  function PDFTabla() {
    let subTotalTotal = 0;
    let totalTotal = 0;
    let data1 = comments;
    if (search) {
      data1 = data1.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.departamentoName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.pedidos.toString().includes(search) ||
          comment.idSurtido.toString().includes(search) ||
          comment.subTotal.toString().includes(search) ||
          comment.total_general.toString().includes(search)
      );
    }
    let data = data1.map((a) => {
      totalTotal = totalTotal + a.total_general;
      subTotalTotal = subTotalTotal + a.subTotal;
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        if (a.is_active == "Si") {
          return [
            a.numero,
            a.fecha,
            a.clientes,
            a.departamentoName,
            a.vendedor,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.subTotal),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.total_general),
          ];
        } else {
          return [
            a.numero,
            a.fecha,
            a.clientes,
            a.departamentoName,
            a.vendedor,
            "CANCELADA",
            0,
          ];
        }
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({ compressPdf: true });
    var img = new Image();

    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Cliente",
          "Departamento",
          "Vendedor",
          "SubTotal",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      headStyles: {
        fillColor: ["#723C85"],
        lineWidth: 0,
      },
      footStyles: {
        fillColor: ["#723C85"],
      },
      theme: "grid",
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(subTotalTotal),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(totalTotal),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Notas.pdf`);
  }

  function excel() {
    let data1 = comments;
    if (search) {
      data1 = data1.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.departamentoName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.pedidos.toString().includes(search) ||
          comment.idSurtido.toString().includes(search) ||
          comment.subTotal.toString().includes(search) ||
          comment.total_general.toString().includes(search)
      );
    }
    const dataExcel = data1.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        if (a.is_active == "Si") {
          return {
            // id: a._id,
            Numero: a.numero,
            Fecha: a.fecha,
            Cliente: a.clientes,
            Departamento: a.departamentoName,
            Vendedor: a.vendedor,
            SubTotal: a.subTotal,
            Importe: a.total_general,
            // Saldo: a.saldo
          };
        } else {
          return {
            // id: a._id,
            Numero: a.numero,
            Fecha: a.fecha,
            Cliente: a.clientes,
            Departamento: a.departamentoName,
            Vendedor: a.vendedor,
            SubTotal: "CANCELADA",
            Importe: 0,
            // Saldo: a.saldo
          };
        }
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoNotas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoNotas",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Cliente",
          "Departamento",
          "Vendedor",
          "SubTotal",
          "Importe",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Cliente",
          "Departamento",
          "Vendedor",
          "SubTotal",
          "Importe",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let subTotalTotal = 0;
    let totalTotal = 0;
    let data1 = comments;
    if (search) {
      data1 = data1.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.departamentoName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.pedidos.toString().includes(search) ||
          comment.idSurtido.toString().includes(search) ||
          comment.subTotal.toString().includes(search) ||
          comment.total_general.toString().includes(search)
      );
    }
    let data = data1.map((a) => {
      totalTotal = totalTotal + a.total_general;
      subTotalTotal = subTotalTotal + a.subTotal;
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        if (a.is_active == "Si") {
          return [
            a.numero,
            a.fecha,
            a.clientes,
            a.departamentoName,
            a.vendedor,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.subTotal),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.total_general),
          ];
        } else {
          return [
            a.numero,
            a.fecha,
            a.clientes,
            a.departamentoName,
            a.vendedor,
            "CANCELADA",
            0,
          ];
        }
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({ compressPdf: true });
    var img = new Image();

    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Cliente",
          "Departamento",
          "Vendedor",
          "SubTotal",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      headStyles: {
        fillColor: ["#723C85"],
        lineWidth: 0,
      },
      footStyles: {
        fillColor: ["#723C85"],
      },
      theme: "grid",
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(subTotalTotal),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(totalTotal),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Notas",
          email: mailTo,
          fileName: "ListadoNotas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Notas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes,
    vendedor,
    telefono,
    salidaBodega,
    descuento
  ) {

    setPhotoEvidencia([])
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idPDFOC}`;
    await axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(async (dataFoto) => {
        setPhotoEvidencia(dataFoto.data.resources);

    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = [];

        if (allArticulosSurtido.length > 10) {
          arrayTabla = allArticulosSurtido.map((a) => {
            return {
              id: a.inventarios[0]._id,
              codigo: a.articulos[0].codigo,
              articulos: a.articulos[0].nombre + " " + a.articulos[0].lineas[0].name + " " + a.articulos[0].familias[0].name,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.articulosPedido[0].precio,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
              fotoArticulo: a.articulos[0].foto,
              foto: process.env.PUBLIC_URL + "sinImagen.png",
            };
          }).filter(function (el) {
            return el != null;
          });
        } else {
          arrayTabla = allArticulosSurtido.map((a) => {
            let foto = a.articulos[0].foto === "No"
              ? process.env.PUBLIC_URL + "sinImagen.png"
              : a.articulos[0].urlFoto;
        
            return {
              id: a.inventarios[0]._id,
              codigo: a.articulos[0].codigo,
              articulos: a.articulos[0].nombre + " " + a.articulos[0].lineas[0].name + " " + a.articulos[0].familias[0].name,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.articulosPedido[0].precio,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
              fotoArticulo: a.articulos[0].foto,
              foto: foto,
            };
          }).filter(function (el) {
            return el != null;
          });
        }
        let dataFinal = Object.values(arrayTabla);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
            cajas: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].cajas += item.cajas;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          groups[val].codigo = item.codigo;
          groups[val].foto = item.foto;
          groups[val].fotoArticulo = item.fotoArticulo;
          return groups;
        }, []);


        let agrupadoValues = Object.values(agrupado);

        let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);

        let TC = agrupadoValues.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let totalParcial = 0;


        const data = agrupadoValues.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return [
            a.foto,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            a.codigo,
            a.articulos,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(totalParcial),
          ];
        });

        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

        const columnStyles = [
          { fontSize: .000001, textColor: [255, 255, 255], cellWidth: 20, },
          { fontSize: 9, textColor: ["#000000"], cellWidth: 18 },
          { fontSize: 9, textColor: ["#000000"], cellWidth: 25 },
          { fontSize: 9, textColor: ["#000000"], cellWidth: 85 },
          { fontSize: 9, textColor: ["#000000"], halign: "right", cellWidth: 25 },
          { fontSize: 9, textColor: ["#000000"], halign: "right", cellWidth: 25 },
        ];
        const doc = new jsPDF({ compressPdf: true });
        var img = new Image();
        var img2 = new Image();
        const backgroundColor = ["#d1d2d4"];
        const backgroundColorBoxLightGray = ["#e6e7e9"];
        const backgroundColorBoxBlack = ["#231f20"];
        const backgroundColorBoxPurple = ["#4d2363"];
        const margins = { left: 5, right: 5 };

        img.src = process.env.PUBLIC_URL + "logo-crembo.jpg";
        doc.addImage(img, "jpg", 9, 12, 53, 12);

        img.src = process.env.PUBLIC_URL + "marcas.jpg";
        doc.addImage(img, "jpg", 9, 28, 192, 10);
        doc.setFontSize(10);

        doc.setFillColor(...backgroundColor);
        doc.rect(5, 40, 200, 6, "F");
        doc.setTextColor("#000000");
        doc.text(`Tel: 55 2454 9626`, 8, 44);
        if(impuestos == 16){
          doc.text(`Factura: ${salidaBodega}`, 80, 44);
          }else{
          doc.text(`Remisión: ${salidaBodega}`, 80, 44);
          }
        doc.text(`Pedido: ${pedidos}`, 120, 44);

        doc.setTextColor("#000000");
        doc.text(`Cliente: ${codigoClientes} - ${clientes}`, 8, 52);

        doc.setTextColor("#4d2363");
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text(`crembo.mx`, 175, 44);
        doc.setTextColor("#000000");

        doc.setFontSize(14);
        doc.setFillColor(...backgroundColorBoxBlack);
        doc.rect(100, 4, 105, 22, "F");
        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(74, 4, 25, 22, "F");
        doc.setTextColor("#ffffff");
        doc.text(`NOTA DE SALIDA`, 125, 12);
        doc.setFont("helvetica", "normal");
        doc.text(`SALIDA BODEGA`, 125, 17);
        doc.text(`México, D. F. a ${fecha}`, 125, 22);


        doc.autoTable({
          head: [
            [
              "",
              "CANTIDAD",
              "MODELO",
              "DESCRIPCION",
              "PRECIO UNITARIO",
              "IMPORTE",
            ],
          ],
          body: dataPDFLimpia,
          bodyStyles:{
            rowHeight : 22,
            valign: "middle"
          },
          styles: {
            fontSize: 10,
            overflow: "linebreak",
            columnWidth: "wrap",
            // font: 'arial',
            overflowColumns: "linebreak",
          },
          headStyles: {
            fillColor: ["#4d2363"],
            lineWidth: 0,
            halign: "center",
            valign: "middle",
            fontSize: 8,
          },
          footStyles: {
            fillColor: ["#4d2363"],
          },
          columnStyles: columnStyles,
          theme: "grid",
          margin: { left: 5, right: 5 },
          startY: 55,

          showFoot: "lastPage",

        didDrawCell: function(data) {
          if (data.column.index === 0 && data.cell.section === 'body') {
             var td = data.cell.raw;
             var img = td
            //  var dim = data.cell.height - data.cell.padding('vertical');
             var textPos = data.cell;
             doc.addImage(img, textPos.x,  textPos.y, 20, 20);
          }
        },
        });

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(5, doc.autoTable.previous.finalY + 1, 36, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFontSize(8);
        doc.text(`TOTAL DE ARTICULOS`, 7, doc.autoTable.previous.finalY + 5);
        doc.setTextColor("#000000");
        doc.text(
          `__________________________________________________________________________________`,
          5,
          doc.autoTable.previous.finalY + 22
        );

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setFont("helvetica", "normal");
        doc.rect(40, doc.autoTable.previous.finalY + 1, 40, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US").format(TM) + " Piezas",
          42,
          doc.autoTable.previous.finalY + 5
        );

        doc.setFontSize(7.5);
        doc.text(
          `1. Esta es una venta en firme y no se acepta ningún tipo de descuento o devolución sin consentimiento previo.`,
          5,
          doc.autoTable.previous.finalY + 11
        );
        doc.text(
          `2. La mercancía viaja por cuenta y riesgo del cliente, no somos responsables por faltante.`,
          5,
          doc.autoTable.previous.finalY + 14
        );
        doc.text(
          `3. El pago de la mercancía debe realizarse en tiempo y forma pactados a la cuenta proporcionada por nosotros.`,
          5,
          doc.autoTable.previous.finalY + 17
        );

        var nota =
          "Debo(emos)  y pagare(mos)  incondicionalmente por  este  pagare a la orden  de CREMBO FASHION en esta plaza,  el día estipulado en  este documento,  la cantidad estipulada  en este  documento,  valor recibido a  mi entera satisfacción, este pagare causará intereses a razón de 2% mensual a partir de la fecha de vencimiento, hasta su total liquidación, pagadero conjuntamente con el principal. Este pagare se rige en los términos de ley general de títulos y operaciones de crédito.";
        var lines = doc.splitTextToSize(nota, 129);
        doc.text(5, doc.autoTable.previous.finalY + 26, lines);

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY + 1, 37, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFont("helvetica", "bold");
        doc.setFontSize(8);
        doc.text(`SUBTOTAL $`, 153, doc.autoTable.previous.finalY + 5);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont("helvetica", "normal");
        doc.rect(181, doc.autoTable.previous.finalY + 1, 24, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(subTotal.toFixed(2)),
          183,
          doc.autoTable.previous.finalY + 5
        );

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY + 8, 37, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFont("helvetica", "bold");
        doc.setFontSize(8);
        doc.text(`IMPUESTO $`, 154, doc.autoTable.previous.finalY + 12);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont("helvetica", "normal");
        doc.rect(181, doc.autoTable.previous.finalY + 8, 24, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(iva),
          183,
          doc.autoTable.previous.finalY + 12
        );

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY + 15, 37, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFont("helvetica", "bold");
        doc.setFontSize(8);
        doc.text(`FLETE`, 154, doc.autoTable.previous.finalY + 19);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont("helvetica", "normal");
        doc.rect(181, doc.autoTable.previous.finalY + 15, 24, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(comisionFlete),
          183,
          doc.autoTable.previous.finalY + 19
        );

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY + 22, 37, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFont("helvetica", "bold");
        doc.setFontSize(8);
        doc.text(`DESCUENTO ${descuento}%`, 153, doc.autoTable.previous.finalY + 26);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont("helvetica", "normal");
        doc.rect(181, doc.autoTable.previous.finalY + 22, 24, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(((subTotal * descuento)/ 100).toFixed(2)),
          183,
          doc.autoTable.previous.finalY + 26
        );

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY + 29, 37, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFont("helvetica", "bold");
        doc.setFontSize(8);
        doc.text(`TOTAL $`, 153, doc.autoTable.previous.finalY + 33);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont("helvetica", "normal");
        doc.rect(181, doc.autoTable.previous.finalY + 29, 24, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(total_general.toFixed(2)),
          183,
          doc.autoTable.previous.finalY + 33
        );

        var lines = doc.splitTextToSize(`Observaciones: ${observaciones}`, 40);
        doc.text(145, doc.autoTable.previous.finalY + 40, lines);
        
        doc.setFont("helvetica", "bold");
        doc.text(
          `Nombre Completo:__________________________________________________________________________  Firma:______________________________`,
          5,
          doc.autoTable.previous.finalY + 50
        );

        doc.setFont("helvetica", "normal");
        doc.setFontSize(6);
        doc.text(
          `ESTE DOCUMENTO NO REPRESENTA UN COMPROBANTE FISCAL`,
          67,
          doc.autoTable.previous.finalY + 60
        );

        if (dataFoto.data.resources && dataFoto.data.resources.length > 0) {
          dataFoto.data.resources.forEach((image, index) => {
            doc.addPage();
            doc.addImage(image.secure_url, "JPE", 5, 5, 200, 200);
          });
        }

        doc.save(`Nota-${numero}.pdf`);
        //   }
        // });
        // });

      })
  })
    .catch((err) => console.log(err))


      .catch((err) => {
        console.log(err);
      });
  }

  async function excelFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.articulosPedido[0].precio,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          return groups;
        }, []);
        let agrupadoValues = Object.values(agrupado);
        let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        let totalParcial = 0;
        const data = agrupadoValues.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return {
            Articulos: a.articulos,
            Cantidad: a.cantidad,
            Precio: a.precio,
            TotalParcial: totalParcial,
          };
        });
        const dataExportExcel = Object.values(data);
        const dataExcelLimpia = dataExportExcel.filter(function (el) {
          return el != null;
        });

        // const data2 = [
        //   {
        //     Cliente: codigoClientes + " " + clientes,
        //     Nota: numero,
        //     RFC: RFC,
        //     Direccion:
        //       calle +
        //       " " +
        //       numero_int +
        //       " " +
        //       numero_ext +
        //       " " +
        //       estado +
        //       " " +
        //       ciudad +
        //       " " +
        //       delegacion +
        //       " " +
        //       colonia +
        //       " " +
        //       cp,
        //     Impuestos: impuestos,
        //     IVA: iva,
        //     TotalGeneral: total_general,
        //     ComisionTDC: comisionTDC,
        //     ComisionFlete: comisionFlete,
        //     Fecha: fecha,
        //     Observaciones: observaciones,
        //   },
        // ];

        var option = {};
        option.fileName = `Nota ${numero}`;
        option.datas = [
          // {
          //   sheetData: data2,
          //   sheetName: `Datos Generales`,
          //   sheetFilter: [
          //     "Nota",
          //     "Fecha",
          //     "Cliente",
          //     "RFC",
          //     "Direccion",
          //     "Impuestos",
          //     "IVA",
          //     "TotalGeneral",
          //     "ComisionTDC",
          //     "ComisionFlete",
          //     "Observaciones",
          //   ],
          //   sheetHeader: [
          //     "Nota",
          //     "Fecha",
          //     "Cliente",
          //     "RFC",
          //     "Direccion",
          //     "Impuestos",
          //     "IVA",
          //     "TotalGeneral",
          //     "ComisionTDC",
          //     "ComisionFlete",
          //     "Observaciones",
          //   ],
          // },
          {
            sheetData: dataExcelLimpia,
            sheetName: `Articulos`,
            sheetFilter: ["Articulos", "Cantidad", "Precio", "TotalParcial"],
            sheetHeader: ["Articulos", "Cantidad", "Precio", "TotalParcial"],
          },
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Salida", field: "salidaBodega", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Pedido", field: "pedidos", sortable: true },
    { name: "Surtió", field: "user", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Piezas", field: "total_cantidad", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.salidaBodega.toLowerCase().includes(search.toLowerCase()) ||
          comment.pedidos.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    let activos = computedComments.filter((e) => e.is_active == "Si");

    let cant = activos.map((c) => parseFloat(c.total_cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalPiezasTabla(TC);

    let to = activos.map((c) => parseFloat(c.total_general));
    let TT = to.reduce((t, total, index) => t + total, 0);
    setTotalImporteTabla(TT);

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "pedidos" &&
      sorting.field != "total_general" &&
      sorting.field != "total_cantidad" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "pedidos" || sorting.field == "numero" || sorting.field == "total_general" || sorting.field == "total_cantidad")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "pedidos" || sorting.field == "numero" || sorting.field == "total_general" || sorting.field == "total_cantidad")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedUser,
    selectedFechaInicio,
    selectedFechaFin,
  ]);


  async function mailBodega(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes,
    vendedor,
    telefono,
    mailArea
  ) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se enviara un mail a la Bodega para surtir!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, enviar!",
    }).then(async(result) => {
      if (result.isConfirmed) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos:a.articulos[0].codigo + " " + a.articulos[0].nombre,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.articulosPedido[0].precio,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
            cajas: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].cajas += item.cajas;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);

        let TC = agrupadoValues.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let totalParcial = 0;

        const data = agrupadoValues.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return [
            a.articulos,
            new Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(a.cajas),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(totalParcial),
          ];
        });

        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 21, 30, 25);

        let alto = 5

        doc.setFontSize(14);
        doc.text(`CREMBO FASHION S.A. DE C.V.`,50, alto + 15);
        doc.setFontSize(10);
        doc.text(`Nezahualcoyotl #145, Local 201, Col. Centro`, 50, alto + 20);
        doc.text(`Del. Cuauhtemoc, Mexico DF CP 06080`, 50, alto + 25);
        doc.text(`Tel: 55 2454 9626, 55 2454 9627 y 55 2454 9628 `, 50, alto + 30);
        doc.text(`Mail: crembo@crembo.net`, 50, alto + 35);

        doc.setFontSize(10);
        doc.text(`Fecha:`, 150, alto + 15);
        doc.text(`${fecha}`, 160, alto + 15);
        doc.text(`Nota:`, 150, alto + 20);
        doc.text(`${numero}`, 160, alto + 20);

        doc.setFontSize(10);
        doc.text(`Cliente: ${codigoClientes} - ${clientes}`, 10, alto + 55);
        doc.text(`RFC: ${RFC}`, 150, alto + 55);
        doc.text(`Direccion de Envio: ${calle} #${numero_ext} Int. ${numero_int} Telefono: ${telefono}`,10, alto + 65);
        doc.text(`Edo: ${estado}, Ciudad: ${ciudad}, Del: ${delegacion}, Col: ${colonia}, CP: ${cp} Paqueteria: ${paqueteria}`,10, alto + 70);


        doc.autoTable({
          head: [
            [
              "Articulo",
              "Cajas",
              "Cantidad",
              "Precio",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
          styles: {
            fontSize: 10,
            overflow: "linebreak",
            columnWidth: "wrap",
            // font: 'arial',
            overflowColumns: "linebreak",
          },
          headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
          margin: { left: 5, right: 5 },
          startY: 80,
          foot: [
            [
              "",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
              new Intl.NumberFormat("en-US").format(TM) + " Piezas",
              "Sub Total",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((subTotal).toFixed(2)),
            ],
            [
              "",
              "",
              "",
              `Comision`,
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((iva).toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((comisionTDC).toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Comision Flete",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((comisionFlete).toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((total_general).toFixed(2)),
            ],
          ],
          showFoot: "lastPage",
        });

        var att = doc.output("arraybuffer");
        var base64File = encode(att);
    
        // Envia el Mail
        axios
          .post(
            URL_SEND_MAIL_ATT,
            {
              subject: "Salida de Mercancia",
              email: mailArea,
              fileName: `Nota-${numero}.pdf`,
              att: base64File,
              text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
             <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
             <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
             <tbody>
             <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
             <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Enviamos la salida de Mercancia de la nota ${numero}</tr>
             </tbody>
             <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
             
             </table>`,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Good job!", "Enviado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    }
  });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_surtidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                {/* <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={
                    e=>{
                      axios
                      .get(`${URL_ARTICULOS_SURTIDOS}Desarrollo`, {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                        },
                      })
                      .then((response) => {
                        alert(response)
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                    }
                  }
                >
                  Desarrollo <i class="far fa-file-pdf"></i>
                </Button> */}
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Notas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Notas</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                if (a.is_active == "No"){
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c" }}>
                      <td>{a.idSurtido}</td>
                      <td>{a.salidaBodega}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.area}</td>
                      <td>{a.vendedor}</td>
                      <td>{a.pedidos}</td>
                      <td>{a.user}</td>
                      <td></td>
                      <td></td>
                        <td>CANCELADA
                          <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.comisionTDC,
                                  a.comisionFlete,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.RFC,
                                  a.paqueteria,
                                  a.area,
                                  a.codigoClientes,
                                  a.vendedor,
                                  a.telefono,
                                  a.salidaBodega,
                                  a.descuento
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button></td>
                    </tr>
                  );
                }else{
                  return (
                    <tr>
                      <td>{a.idSurtido}</td>
                      <td>{a.salidaBodega}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.area}</td>
                      <td>{a.vendedor}</td>
                      <td>{a.pedidos}</td>
                      <td>{a.user}</td>
                      <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.total_general)}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                        }).format(a.total_cantidad)}
                      </td>
                        <>
                          <td>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.comisionTDC,
                                  a.comisionFlete,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.RFC,
                                  a.paqueteria,
                                  a.area,
                                  a.codigoClientes,
                                  a.vendedor,
                                  a.telefono,
                                  a.salidaBodega,
                                  a.descuento
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="primary"
                              onClick={(e) =>
                                excelFOC(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.comisionTDC,
                                  a.comisionFlete,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.RFC,
                                  a.paqueteria,
                                  a.area,
                                  a.codigoClientes
                                )
                              }
                            >
                              <i class="far fa-file-excel"></i>
                            </Button>

                            <Button
                              size="sm"
                              className="btn"
                              color="info"
                              onClick={(e) =>
                                mailBodega(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.comisionTDC,
                                  a.comisionFlete,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.RFC,
                                  a.paqueteria,
                                  a.area,
                                  a.codigoClientes,
                                  a.vendedor,
                                  a.telefono,
                                  a.mailArea
                                )
                              }
                            >
                              @
                            </Button>
                          </td>
                        </>
                    </tr>
                  );
                }

              })}
                <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalImporteTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                  }).format(totalPiezasTabla)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />
      {loader}
    </>
  );
}

export default ListadoSurtidos;
